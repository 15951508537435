<template>
    <uw-content title="项目类型" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.dialogCreate.Open()">新的项目类型</el-button>
        </template>

        <!-- 表格 -->
        <uw-table :show-header="false" :show-footer="false">

            <!-- 数据 -->
            <template slot="data">
                <vxe-table ref="xTable" :data="table" height="100%">
                    <vxe-column width="60" type="seq" title="ID"></vxe-column>

                    <vxe-column min-width="160" field="name" title="名称"></vxe-column>

                    <vxe-column width="120" field="system" title="类型">
                        <template #default="{ row }">
                            <el-link :underline="false" v-if="row.system" type="info">系统默认</el-link>
                            <el-link :underline="false" v-if="!row.system" >自定义</el-link>
                        </template>
                    </vxe-column>

                    <vxe-column width="120" field="state" title="状态">
                        <template #default="{ row }">
                            <el-link :underline="false" v-if="row.state" type="success">已启用</el-link>
                            <el-link :underline="false" v-if="!row.state" type="info">未启用</el-link>
                        </template>
                    </vxe-column>

                    <vxe-column width="120" field="created_user.name" title="创建人" sortable></vxe-column>
                    <vxe-column width="120" field="created_user.name" title="更新人" sortable></vxe-column>

                    <vxe-column width="140" field="created_at" title="创建日期" sortable></vxe-column>
                    <vxe-column width="140" field="updated_at" title="最后更新日期" sortable></vxe-column>

                    <vxe-column width="130" title="管理" fixed="right">
                        <template #default="{ row }">
                            <el-link type="primary" icon="el-icon-edit-outline" class="w-margin-r-10" @click="$refs.dialogUpdate.Open(row.id)">修改</el-link>
                            <el-link type="danger" icon="el-icon-delete" @click="TableDelete(row.id)">移除</el-link>
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>
            
        </uw-table>

        <projects-type-create ref="dialogCreate" @onChange="TableSearch" />
        <projects-type-update ref="dialogUpdate" @onChange="TableSearch" />
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            loading: false,

            table: [],
        }
    },

    mounted() {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.$http.post('/9api/projects-type/search-all', { append: ['created_user', 'updated_user' ] }).then(rps => {
                this.table = rps.data
            })
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 移除
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.$http.post('/9api/projects-type/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        },
    }
}
</script>